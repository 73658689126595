<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">New Institution</div>
  </div>
  <div class="layout-content-section">
    <div
      class="
        content-header-section
        d-flex
        justify-content-start
        align-items-center
      "
    >
      <div class="content-subheader-box">Add New Institution</div>
    </div>
    <div class="company-detail-box-outer" style="height: calc(100vh - 201px)">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label for="formFullNameInput" class="form-label"
                  >Institution Full Name<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="institutionfullname"
                  class="form-control text-capitalize"
                  id="formFullNameInput"
                  placeholder="Enter Institution Full Name"
                  autocomplete="off"
                  maxlength="50"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label for="formShortNameInput" class="form-label"
                  >Institution Short Name<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="institutionshortname"
                  class="form-control text-capitalize"
                  id="formShortNameInput"
                  placeholder="Enter Institution Short Name"
                  autocomplete="off"
                  maxlength="20"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="custom-form-group text-center">
                <button type="button" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      institutionfullname: "",
      institutionshortname: "",
    };
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.company-detail-box-outer {
  padding: 16px 16px 0;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}
.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}
.customer-label-group {
  margin-bottom: 16px;
}
.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 525px;
}
.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}
.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}
.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}
.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}
.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}
.text-star-blank {
  color: #c1c5c9;
}
</style>